// src/components/CTA.js
import React from "react";
import Container from "@mui/material/Container";
import Box from "@mui/material/Box";
import Typography from "@mui/material/Typography";
import Button from "@mui/material/Button";
import { hero } from "../assets";
import Grow from "@mui/material/Grow";
import { Link } from "react-router-dom";

function CTA() {
  return (
    <div style={{ background: "#5C6BC0" }}>
      <Container maxWidth="lg">
        {/* Text Block */}
        <Grow in={true} timeout={1200} appear={true}>
          <Box mt={3}>
            <Typography variant="h3" gutterBottom>
              Welcome to Streamascape – Your Ultimate Free Gaming Paradise!
            </Typography>

            <Typography variant="body1" paragraph>
            Hello, gaming enthusiasts! We're thrilled to welcome you to Streamascape.com, your new go-to destination for endless entertainment and excitement. Here at Streamascape, we offer a sprawling selection of free online games that cater to every taste and age group. Whether you're here to dive into epic adventures alone or looking for the thrill of competition with friends and fellow gamers around the globe, we've got something special for you. And the best part? Our games are fully optimized for all your devices – be it a desktop, tablet, or mobile phone, ensuring a seamless gaming experience wherever you are.

            </Typography>

            <Typography variant="h5" paragraph>
            NO HASSLES, ALL FUN – INSTANT PLAY WITHOUT THE WAIT!


            </Typography>

            <Typography variant="body1" paragraph>
            Forget about tedious downloads or sign-ups. At Streamascape, we believe in instant fun. Choose from our wide array of games and start playing right away with just a simple click. Discover Streamascape exclusives like Galactic Gems, Warrior Legends, Pixel Sprint, and Shadow Quest, or dive into fan favorites including Treasure Hunt, Brainy Quiz, Ninja Dash, and so many more!


            </Typography>

            <Typography variant="body1" paragraph>
            At Streamascape, variety is the spice of gaming life. From brain-teasing puzzle games and classic card games to action-packed .io games and heart-racing racing titles, we've curated a collection that promises to keep you entertained for hours on end. Challenge an opponent in our competitive 2-player games or team up for cooperative play in our multiplayer offerings. Whatever your gaming preference, Streamascape shines with possibilities.



            </Typography>

            <Box
              style={{
                background: `url(${hero})`,
                backgroundSize: "cover",
                backgroundAttachment: "fixed",
                maxWidth: "100%",
                width: "100%",
                height: "250px",
                margin: "2rem auto",
              }}
            />

            

            <Typography variant="body1" paragraph>
              The entertainment on our site is designed purely for enjoyment.
              streamascape.com does not facilitate real-money betting nor does
              it guarantee monetary rewards. Access to our gaming world is
              complimentary, welcoming anyone who satisfies our entry
              requirements.
            </Typography>
            <Button
              variant="contained"
              color="secondary"
              component={Link}
              to="/contacts"
            >
              Contacts
            </Button>
            <Typography
              variant="h4"
              paragraph
              fontWeight="900"
              textAlign="right"
            >
              18+
            </Typography>
            <Typography
              variant="h6"
              paragraph
              fontWeight="900"
              textAlign="right"
            >
              {" "}
              All games are intended for a mature audience. By accessing and
              using this website, users declare that they are of legal age to
              participate in the entertainment provided.
            </Typography>
          </Box>
        </Grow>
      </Container>
    </div>
  );
}

export default CTA;
