import { createTheme } from "@mui/material/styles";

const theme = createTheme({
  palette: {
    primary: {
      dark: "#1A237E", // Original dark shade
      main: "#3F51B5", // Vibrant primary color
      main600: "#3F51B5",
      main500: "#5C6BC0", // Mid-dark shade
      main400: "#7986CB", // Useful for gradients or shadows
      main300: "#9FA8DA", // Mid shade
      main200: "#C5CAE9", // Lighter mid shade
      main100: "#D1D9FF", // Near light shade
      main50: "#E8EAF6",/// Very
    },
    text: {
      primary: "#ffffff",
    },
    secondary: {
      main: "#8C9EFF",
      secondary: "#536DFE",
    },
    success: {
      main: "#C8E4B2",
    },
  },
  typography: {
    fontFamily: "Righteous, sans-serif",
    body1: {
      fontSize: "18px",
    },
    h3: {
      fontSize: "1.7rem",
      "@media (min-width:600px)": {
        fontSize: "3rem",
      },
    },
    h4: {
      fontSize: "1.4rem",
      "@media (min-width:600px)": {
        fontSize: "2rem",
      },
    },
  },
  spacing: [0, 4, 8, 16, 32, 64],
});

export default theme;
